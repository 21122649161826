<template>
  <transition name="fade">
    <div v-if="popup.fade">
      <div class="pop-up-bg" @click.prevent="close(), (success = false)"></div>
      <div
        class="pop-up"
        :class="
          $route.name == 'Home' || $route.name == 'Settings'
            ? 'pop-up--bottom'
            : ''
        "
      >
        <a
          class="pop-up__close icon icon-close"
          @click.prevent="close(), (success = false)"
        ></a>

        <!-- home -->
        <div v-if="popup.mod == 'Home'">
          <h2>{{$root.langText.menu.menuDirectory}}</h2>
          <ul class="home__menu" style="overflow: auto; max-height: 61vh">
            <router-link to="/clients" tag="li">
              <div class="menu-icon menu-customers"></div>
              <p>{{$root.langText.directory.clients}}</p>
            </router-link>
            <router-link to="/counterparties" tag="li">
              <div class="menu-icon menu-counterparties"></div>
              <p>{{$root.langText.directory.counterparties}}</p>
            </router-link>
            <router-link to="/city" tag="li">
              <div class="menu-icon menu-directory"></div>
              <p>{{$root.langText.directory.city}}</p>
            </router-link>
            <router-link to="/drivers" tag="li">
              <div class="menu-icon menu-directory"></div>
              <p>{{$root.langText.directory.drivers}}</p>
            </router-link>
            <router-link to="/car" tag="li">
              <div class="menu-icon menu-directory"></div>
              <p>{{$root.langText.directory.car}}</p>
            </router-link>
            <router-link to="/trailers" tag="li">
              <div class="menu-icon menu-directory"></div>
              <p>{{$root.langText.directory.trailers}}</p>
            </router-link>
            <router-link
              to="/leads"
              tag="li"
              v-if="
                $root.app !== 'SoftProCrmAppRioTransTest' &&
                $root.app !== 'SoftProCrmAppRioTrans'"
            >
              <div class="menu-icon menu-directory"></div>
              <p>{{$root.langText.directory.leads}}</p>
            </router-link>
          </ul>
        </div>

        <!-- settings password-->
        <div v-if="popup.mod == 'Password'">
          <h2>{{$root.langText.setting.changePassword}}</h2>
          <form class="form" v-if="!success">
            <div class="form__input pop-up__passworrd">
              <div class="icon icon-lock"></div>
              <input
                :placeholder="$root.langText.setting.password"
                :type="passwordOldType"
                v-model.trim="passwordOld"
                :class="{
                  invalid:
                    ($v.passwordOld.$dirty && !$v.passwordOld.required) ||
                    ($v.passwordOld.$dirty && !$v.passwordOld.minLength),
                }"
              />
              <button
                @click.prevent="
                  passwordOldType =
                    passwordOldType == 'password' ? 'text' : 'password'
                "
                class="icon icon-eye"
              ></button>
            </div>

            <div class="form__input pop-up__passworrd">
              <div class="icon icon-new-lock"></div>
              <input
                :placeholder="$root.langText.setting.newPassword"
                :type="passwordNewType"
                v-model.trim="passwordNew"
                :class="{
                  invalid:
                    ($v.passwordNew.$dirty && !$v.passwordNew.required) ||
                    ($v.passwordNew.$dirty && !$v.passwordNew.minLength),
                }"
              />
              <button
                @click.prevent="
                  passwordNewType =
                    passwordNewType == 'password' ? 'text' : 'password'
                "
                class="icon icon-eye"
              ></button>
            </div>

            <div class="form__input pop-up__passworrd">
              <div class="icon icon-repeat-lock"></div>
              <input
                :placeholder="$root.langText.setting.repeatPassword"
                :type="passwordRepeatType"
                v-model.trim="passwordRepeat"
                :class="{
                  invalid:
                    ($v.passwordRepeat.$dirty && !$v.passwordRepeat.required) ||
                    ($v.passwordRepeat.$dirty &&
                      !$v.passwordRepeat.minLength) ||
                    ($v.passwordRepeat.$dirty &&
                      !$v.passwordRepeat.sameAsPassword),
                }"
              />
              <button
                @click.prevent="
                  passwordRepeatType =
                    passwordRepeatType == 'password' ? 'text' : 'password'
                "
                class="icon icon-eye"
              ></button>
            </div>
            <div class="pop-up__btn pop-up__btn--one">
              <button
                @click.prevent="sendPasswordNew()"
                class="btn btn-primary"
              >
                {{$root.langText.save}}
              </button>
            </div>
          </form>
          <div class="form" v-if="success">
            <div class="form__input pop-up__passworrd">
              <div class="icon icon-new-lock"></div>
              <p>{{$root.langText.setting.donePassword}}</p>
            </div>
            <div class="pop-up__btn pop-up__btn--one">
              <button
                @click.prevent="$emit('close'), (success = false)"
                class="btn btn-primary"
              >
                {{$root.langText.good}}
              </button>
            </div>
          </div>
        </div>

        <!-- date -->
        <div v-if="popup.mod == 'Date'">
          <h2 style="margin-bottom: 10px !important">{{ popup.title }}</h2>
          <div style="margin: 0 20px">
            <div v-if="popup.modTime == 'is-range'">
              <v-date-picker
                v-model="date"
                is-expanded
                :locale="$root.lang"
                :is-dark="dateTheme"
                :is-required="true"
                :min-date="min"
                is-range
                is24hr
                @dayclick="clickDate(date)"
              />
            </div>
            <div v-else-if="popup.modTime">
              <v-date-picker
                v-model="date"
                is-expanded
                :locale="$root.lang"
                :is-dark="dateTheme"
                :is-required="true"
                :min-date="min"
                mode="dateTime"
                :minute-increment="10"
                @dayclick="clickDate(date)"
              />
            </div>
            <div v-else>
              <v-date-picker
                v-model="date"
                is-expanded
                :locale="$root.lang"
                :is-dark="dateTheme"
                :is-required="true"
                :min-date="min"
                is24hr
                @dayclick="clickDate(date)"
              />
            </div>
          </div>

          <div class="pop-up__btn pop-up__btn--one" :style="popup.modTime ? 'position:relative;z-index:3' : ''">
            <a class="btn btn-primary" @click.prevent="SaveDate">{{popup.mod == 'Date' ? 'Ок' : $root.langText.save}}</a>
          </div>
        </div>

        <!-- creat/update contacts settings-->
        <form class="form" v-if="popup.mod == 'ContactsSettings'">
          <h2 v-if="popup.bd">{{$root.langText.contacts.changeContacts}}</h2>
          <h2 v-else>{{$root.langText.contacts.addContacts}}</h2>

          <ContactViewSettings
            @change="changeContactSettings"
            :data="{ popup, items, client }"
          />

          <div class="pop-up__btn pop-up__btn--one">
            <a class="btn btn-primary" @click.prevent="saveContactSettings"
              >{{$root.langText.save}}</a
            >
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.form__input a.invalid,
.form__input input.invalid,
.form__input p.invalid {
  border-bottom-color: red;
}
.vc-date{
  display: none !important;
}
.vc-time-picker[data-v-d70d98e2]{
  padding: 15px 8px;
}
</style>


<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import ContactViewSettings from "@/components/app/ContactViewSettings";

export default {
  props: ["popup", "client", "dateInput"],

  data: () => ({
    //password
    passwordOld: "",
    passwordNew: "",
    passwordRepeat: "",
    passwordOldType: "password",
    passwordNewType: "password",
    passwordRepeatType: "password",
    success: false,
    warning: false,

    //date
    date: new Date(),
    dateTheme: false,
    min: "",

    //contact
    id: "",
    items: [
      {
        id: "",
        name: "",
        type: "phone",
        val: "",
      },
    ],
  }),

  validations: {
    passwordOld: { required, minLength: minLength(4) },
    passwordNew: { required, minLength: minLength(4) },
    passwordRepeat: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs("passwordNew"),
    },
  },

  created() {
    if (localStorage.getItem("theme") == "dark") {
      this.dateTheme = true;
    }
    function date(date) {
      if (date) {
        var year = date.substr(6, 4);
        var day = date.substr(0, 2);
        var month = date.substr(3, 2);
        var resultDate = new Date(year + "-" + month + "-" + day);
        return resultDate;
      }
    }
    //if time
    if(this.popup.modTime == 'is-range'){
      if(this.popup.date){
        this.date = this.popup.date;
      } else{
        this.date = new Date();
      }
    } else if(this.popup.modTime){
      if(this.popup.date){
        this.date = this.popup.date;
      } else{
        this.date = new Date();
      }
    } 
    //if data
    else{
      if (date(this.popup.date) === undefined) {
        this.date = new Date();
      } else {
        this.date = date(this.popup.date);
      }
    }
    if (this.popup.min) {
      this.min = date(this.popup.min);
    } else {
      this.min = "";
    }
  },

  methods: {
    close() {
      this.$root.doubleClick.click = "0";
      this.$root.doubleClick.date = "";
      this.$emit("close");
    },

    clickDate(data) {
      this.$root.doubleClick.click = ++this.$root.doubleClick.click;
      if (this.$root.doubleClick.date == data) {
        let date = this.date;
        if (this.popup.title == "Дата доставки") {
          this.$emit("ReadyDate", [{ date: date, index: 0 }]);
        } else {
          this.$emit("ReadyDate", [
            { date: date, index: 1, input: this.popup.input },
          ]);
        }

        this.close();
      } else {
        this.$root.doubleClick.date = data;
      }
    },

    //data
    SaveDate() {
      let date = this.date;
      if (this.popup.title == "Дата доставки") {
        this.$emit("ReadyDate", [{ date: date, index: 0 }]);
      } else {
        this.$emit("ReadyDate", [
          { date: date, index: 1, input: this.popup.input },
        ]);
      }
      this.close();
    },

    //settings password
    sendPasswordNew() {
      var jsonPassword = {
        id: this.popup.id,
        pass: this.passwordNew,
      };
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        this.success = true;
        var password = this.$store.dispatch("updatePassword", jsonPassword);
      } catch (e) {}
    },

    //contacts settings
    saveContactSettings() {
      if (this.popup.info) {
        var bd = this.popup.bd;
        var data = {
          id: this.popup.id_contact,
          [bd]: this.items[0].val,
        };
      } else {
        var type;
        if (this.items[0].type == "email") {
          if (!this.client.client.email) {
            type = "email";
          } else {
            type = "email2";
          }
        } else {
          if (!this.client.client.phone) {
            type = "phone";
          } else {
            type = "phone2";
          }
        }

        var data = {
          id: this.popup.id_contact,
          [type]: this.items[0].val,
        };
      }

      this.$store.dispatch("updateTeam_users", data);
      var thisApp = this;
      setTimeout(function () {
        thisApp.$emit("close");
      }, 500);
    },
    changeContactSettings(data) {
      this.items[0].type = data.type;
      this.items[0].val = data.value;
    },
  },
  components: {
    ContactViewSettings,
  },
};
</script>