<template>
  <div class="pop-up__contact">
    <div class="pop-up__contact__icon" :class="slide ? 'active' : ''">
      <div class="icon icon-arrow-down" v-if="dropdownIn"></div>
      <a @click.prevent="dropdown">
        <div class="icon" :class="active.icon_class"></div>
      </a>
    </div>
    <ul class="pop-up__contact__ul-setting" v-if="slide && info">
      <li
        v-for="(infoRes, index) in info"
        :key="index"
      >
        <a @click.prevent="changeSlide(infoRes)">
          <div class="icon" :class="infoRes.icon_class"></div>
        </a>
      </li>
    </ul>
    <div :class="active.input_class">
      <input 
        :type="active.type == 'tel' ? 'number' : 'text'"
        autocomplete="off"
        @focus="focus"
        :placeholder="active.placeholder" 
        v-model="input"
        v-on:change="changeInput"
      />
      <div class="form__close" @click.prevent="input = '', changeInput()" v-if="input != ''"></div>
    </div>
  </div>
</template>

<style lang="scss">
  .pop-up__contact__icon, .pop-up__contact{
    position: relative;
  }
  .icon-arrow-down{
    position: absolute;
    right: 5px;
    top: -5px;
    z-index: -1;
  }
  .pop-up__contact__ul-setting{
    position: absolute;
    top: 30px;
    z-index: 3;
    background: #fff;
    padding: 5px;
    box-shadow: 4px 4px 10px rgba(232,236,229,.8), -4px -4px 10px rgba(246,246,246,.8);
  }
  input{
    background-image: linear-gradient(to right,#c3dbf6 70%,rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 13px 0px;
    background-repeat: repeat-x;
  }
  .pop-up__contact .form__close{
    position: absolute;
    right: 0;
    top: 0px;
  }
</style>


<script>


export default {
  props: ['data'],

  data:() => ({
    active: {
      input_class: 'pop-up__contact__phone',
      icon_class: 'icon-phone',
      type: 'tel',
      placeholder: '380 (000) - 000 - 00 - 00',
    },
    info: [
      {
        input_class: 'pop-up__contact__phone',
        icon_class: 'icon-phone',
        type: 'tel',
        placeholder: '380 (000) - 000 - 00 - 00',

      },
      {
        input_class: 'pop-up__contact__mail',
        icon_class: 'icon-mail',
        type: 'email',
        placeholder: '@mail.com',
      }
    ],
    slide: false,
    input: '',
    dropdownIn: false,
  }),

  created() {
    this.input = this.data.popup.info

    if(this.data.popup.bd){
      this.dropdownIn = false
      if(this.data.popup.type == 'tel'){
        this.active = this.info[0]
      } else{
        this.active = this.info[1]
      }
    } else{
      if(this.data.client.email && this.data.client.phone){
        this.dropdownIn = true
      }
      if(!this.data.client.email){
        this.active = this.info[0]
      }if(!this.data.client.phone){
        this.active = this.info[1]
      }
    }
  },

  methods: {
    dropdown(){
      if(this.data.client.email && this.data.client.phone && !this.data.popup.bd){
        this.slide = !this.slide
      }
    },
    focus(){
      if(this.active.type == 'tel' && this.input === ''){
        this.input = '380'
      }
    },

    changeSlide(arr){
      this.slide = !this.slide
      this.active = arr
      this.input = ''
    },
    changeInput(){
      const input = this.input
      const type = this.active.type

      this.$emit('change', {value: input, type, bd: ''})
    }
  },
  
}
</script>